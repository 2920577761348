<template>
  <div>
    <div v-if="state === 0">
      <!-- form -->
      <validation-observer
        ref="registerForm"
        #default="{invalid}"
      >
        <b-form
          class="auth-register-form mt-2"
          @submit.prevent="updatePassword"
        >
          <template v-if="needCurrentPassword">
            <b-card-title class="mb-1">
              {{ $t('password.old') }}
            </b-card-title>
            <!-- current password -->
            <b-form-group
              label-for="currentPassword"
            >
              <template v-slot:label>
                {{ $t('user.currentPassword') }} <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                :name="$t('user.currentPassword')"
                vid="currentPassword"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="current-confirmation"
                    v-model="user.currentPassword"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    name="current-password"
                    placeholder="············"
                    autocomplete="off"
                    @input="checkPassword"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </template>
          <!-- password rules -->
          <b-card-title class="mb-1">
            {{ $t('password.title') }}
          </b-card-title>
          <b-card-text class="mb-2">
            <div class="input_container">
              <h5 class="text-primary">
                {{ $t('password.message') }}:
              </h5>
              <ul class="check_password_slot">
                <li
                  class="password_rule"
                  :class="{ is_valid: containsNCharacters }"
                >
                  {{ minimumPasswordLength }} {{ $t('password.validations.containNCharacters') }}
                </li>
                <li
                  class="password_rule"
                  :class="{ is_valid: containsNumber }"
                >
                  {{ $t('password.validations.containsNumber') }}
                </li>
                <li
                  class="password_rule"
                  :class="{ is_valid: containsUpperAndLowercase}"
                >
                  {{ $t('password.validations.containsUppercase') }}
                </li>
                <li
                  class="password_rule"
                  :class="{ is_valid: containsSpecialCharacter }"
                >
                  {{ $t('password.validations.containsSpecialCharacter') }}
                </li>
              </ul>
              <div
                class="checkmark_container"
                :class="{ show_checkmark: validPassword }"
              >
                <svg
                  width="50%"
                  height="50%"
                  viewBox="0 0 140 100"
                >
                  <path
                    class="checkmark"
                    :class="{ checked: validPassword }"
                    d="M10,50 l25,40 l95,-70"
                  />
                </svg>
              </div>
            </div>
          </b-card-text>
          <!-- /password rules -->
          <!-- password -->
          <b-form-group
            label-for="password"
          >
            <template v-slot:label>
              {{ $t('user.password') }} <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              :name="$t('user.password')"
              vid="password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="password"
                  v-model="user.password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false:null"
                  name="password"
                  placeholder="············"
                  autocomplete="off"
                  @input="checkPassword"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- password confirmation-->
          <b-form-group
            label-for="passwordConfirmation"
          >
            <template v-slot:label>
              {{ $t('user.passwordConfirmation') }} <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              :name="$t('user.passwordConfirmation')"
              vid="passwordConfirmation"
              rules="required|confirmed:password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="password-confirmation"
                  v-model="user.passwordConfirmation"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false:null"
                  name="register-password"
                  placeholder="············"
                  autocomplete="off"
                  @input="checkPassword"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            variant="primary"
            block
            type="submit"
            :disabled="invalid || !validPassword"
          >
            {{ $t('password.actions.changePassword') }}
          </b-button>
        </b-form>
      </validation-observer>
    </div>
    <!-- ResetPasswordResult -->
    <div v-else-if="state === 1">
      <b-card-title
        title-tag="h2"
        class="font-weight-bold mb-1"
      >
        {{ $t('login.resetPasswordResult.title') }}
      </b-card-title>
      <b-card-text class="mb-2">
        {{ $t('login.resetPasswordResult.message') }}
      </b-card-text>
    </div>
    <!-- /ResetPasswordResult -->
    <!-- ResetPasswordErrorResult -->
    <div v-else-if="state === 2">
      <b-card-title
        title-tag="h2"
        class="font-weight-bold mb-1"
      >
        {{ $t('login.resetPasswordResult.errorTitle') }}
      </b-card-title>
      <b-card-text class="mb-2">
        {{ errorMessage }}
      </b-card-text>
      <b-button
        block
        @click="toFrorm"
      >
        {{ $t('login.button.retry') }}
      </b-button>
    </div>
    <!-- /ResetPasswordErrorResult -->
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AuthStoreModule from '../storeModule'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  props: {
    token: {
      type: String,
      default: null,
    },
    username: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      status: '',
      // validation
      required,
      email,
      password_length: 0,
      minimumPasswordLength: 8,
      containsNCharacters: false,
      containsNumber: false,
      containsUpperAndLowercase: false,
      containsSpecialCharacter: false,
      validPassword: false,
      user: {
        password: '',
        passwordConfirmation: '',
        username: '',
        token: '',
        currentPassword: null,
      },
      errorMessages: {
        unchangedPassword: this.$t('password.error.unchangedPassword'),
        wrongPassword: this.$t('password.error.wrongPassword'),
        unexpectedError: this.$t('password.error.unexpectedError'),
        expiredSignature: this.$t('password.error.expiredSignature'),
      },
      AUTH_STORE_MODULE_NAME: 'auth',
      state: 0,
      errorMessage: '',
      needCurrentPassword: true,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  watch: {
    token(newValue, oldValue) {
      if (newValue) {
        this.user.token = newValue
        this.needCurrentPassword = false
      } else {
        this.needCurrentPassword = true
      }
    },
    username(newValue, oldValue) {
      this.user.username = newValue
    },
  },
  mounted() {
    // Register module
    if (!store.hasModule(this.AUTH_STORE_MODULE_NAME)) store.registerModule(this.AUTH_STORE_MODULE_NAME, AuthStoreModule)
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.AUTH_STORE_MODULE_NAME)) store.unregisterModule(this.AUTH_STORE_MODULE_NAME)
  },
  methods: {
    showErrorMessage(errorMessage) {
      this.errorMessage = errorMessage
      const self = this
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: self.$t('login.resetPasswordResult.errorTitle'),
          icon: 'LinkIcon',
          variant: 'danger',
          text: errorMessage,
        },
      })
    },
    showSuccessfulMessage() {
      const self = this
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: self.$t('login.resetPasswordResult.title'),
          icon: 'SaveIcon',
          variant: 'success',
          text: self.$t('login.resetPasswordResult.message'),
        },
      })
    },
    checkPassword() {
      this.passwordLength = this.user.password.length
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/

      if (this.passwordLength >= this.minimumPasswordLength) {
        this.containsNCharacters = true
      } else {
        this.containsNCharacters = false
      }

      this.containsNumber = /\d/.test(this.user.password)
      this.containsUpperAndLowercase = /[A-Z]/.test(this.user.password) && /[a-z]/.test(this.user.password)
      this.containsSpecialCharacter = format.test(this.user.password)

      if (this.containsNCharacters === true
					&& this.containsSpecialCharacter === true
					&& this.containsUpperAndLowercase === true
					&& this.containsNumber === true) {
        this.validPassword = true
      } else {
        this.validPassword = false
      }
    },
    toFrorm() {
      this.state = 0
    },
    updatePassword() {
      const self = this
      const passwordData = {
        password: this.user.password,
      }
      if (this.needCurrentPassword) {
        passwordData.currentPassword = this.user.currentPassword
        store.dispatch('auth/changePassword', { passwordData })
          .then(response => {
            self.state = 1
            self.showSuccessfulMessage()
          })
          .catch(error => {
            self.state = 2
            const errorKey = error.response.data.message
            let errorMessage = self.errorMessages.unexpectedError
            if (errorKey in self.errorMessages) {
              console.info('self.errorMessages:', self.errorMessages[errorKey])
              errorMessage = self.errorMessages[errorKey]
            }
            self.showErrorMessage(errorMessage)
          })
      } else {
        passwordData.token = this.user.token
        store.dispatch('auth/resetPassword', { passwordData })
          .then(response => {
            self.state = 1
            self.showSuccessfulMessage()
          })
          .catch(error => {
            self.state = 2
            console.info(error.response)
            const errorKey = error.response.data.message
            let errorMessage = self.errorMessages.unexpectedError
            if (errorKey in self.errorMessages) {
              console.info('self.errorMessages:', self.errorMessages[errorKey])
              errorMessage = self.errorMessages[errorKey]
            }
            self.showErrorMessage(errorMessage)
          })
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">

@import '@core/scss/vue/pages/page-auth.scss';

.input_container {
	padding: 15px 15px 0 15px;
  border: 1px solid #0f6e7c;;
  border-radius: 5px;
}

.password_rule {
	margin-bottom: 8px;
	color: #525f7f;
	position: relative;
}

.password_rule:before {
  content: "";
	width: 0%; height: 2px;
	background: #4faebc;
	position: absolute;
	left: 0; top: 50%;
	display: block;
	transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.check_password_slot {
	padding-left: 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
  font-size: 12px;
}

/* Checkmark & Strikethrough --------- */

.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 100%;
}

.checkmark_container {
	border-radius: 50%;
	position: absolute;
	top: 175px;
  right: 35px;
	background: #0f6e7c;
	width: 50px; height: 50px;
	visibility: hidden;
	opacity: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity .4s ease;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.username {
  margin-left: 10px;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 20;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.checked { animation: draw 0.5s ease forwards; }

@keyframes draw {
  to { stroke-dashoffset: 0; }
}
</style>
