import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    resetPassword(ctx, { passwordData }) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/v1/auth/reset-password', passwordData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changePassword(ctx, { passwordData }) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/v1/auth/change-password', passwordData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getResetPassword(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/auth/reset-password', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
